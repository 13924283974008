export const USER_DATA = "USER_DATA";
export const LOGIN_STATUS = "LOGIN_STATUS";
export const LOADER = "LOADER";
export const SUBSCRIPTON = "SUBSCRIPTON";

export const initialState = {
  loginStatus: false,
  userData: null,
  loading: false,
};

export const setLoginStatus = (loginStatus) => ({
  type: LOGIN_STATUS,
  loginStatus,
});
export const setUserData = (userData) => ({
  type: USER_DATA,
  userData,
});
export const setSubscription = (payload) => ({
  type: SUBSCRIPTON,
  payload,
});
export const setLoading = (loading) => ({
  type: LOADER,
  loading,
});

export const globalReducer = (state, action, initialState) => {
  console.log(state, action, initialState);
  switch (action.type) {
    case LOGIN_STATUS:
      return { ...state, loginStatus: action.loginStatus };
    case SUBSCRIPTON:
      return { ...state, subscription: action.payload };
    case USER_DATA:
      return { ...state, userData: { ...state.userData, ...action.userData } };
    case LOADER:
      return { ...state, loading: action.loading };

    default:
      return state;
  }
};
