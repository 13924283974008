import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

// standards
import AddPatientByUrl from "../components/ClinicDashboard/AddPatientByUrl";

const Routers = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/add-patient-by-url/:clinicId">
          <AddPatientByUrl />
        </Route>
        <Route render={() => <p>Page Not Found.</p>} />
      </Switch>
    </Router>
  );
};

export default Routers;
