import { gql } from "@apollo/client";

export const SEND_BULK_INVITE = gql`
  mutation MyMutation($data: SendBulkInviteFromFileRequest!) {
    sendBulkInviteFromFile(data: $data) {
      message
      statusCode
    }
  }
`;

export const SEND_MAIL_INVITE = gql`
  mutation MyMutation($data: SendMailInviteRequest!) {
    sendMailInvite(data: $data) {
      message
      statusCode
    }
  }
`;

export const UPDATE_PROFILE = gql`
  mutation MyMutation($data: UpdateClinicRequest!) {
    updateClinic(data: $data) {
      message
      statusCode
      data {
        name
        registrationNumber
        signature
        specialities
        status
        termsAndConditionAccepted
        about
        address
        clinicType
        contactDetails
        contract
        contractAccepted
        description
        emergencyNumber
        gstin
        helplineNumber
        id
        logoLink
        mail
      }
    }
  }
`;

export const POST_PATIENT_ADD = gql`
  mutation PatientMutation($data: AddPatientRequest!) {
    addPatient(data: $data) {
      statusCode
      message
      data {
        accountId
      }
    }
  }
`;

export const CLINIC_CREATION = gql`
  mutation MyMutation($data: AddClinicCreationRequest!) {
    addClinicCreationRequest(data: $data) {
      statusCode
      message
      data {
        contract
        id
      }
    }
  }
`;

export const CONFIRM_CLINIC_CREATION = gql`
  mutation MyMutation($data: SendClinicCreationRequest!) {
    sendClinicCreationRequest(data: $data) {
      message
      statusCode
    }
  }
`;

export const CLINIC_APPROVAL = gql`
  mutation MyMutation($data: ApproveClinicCreationRequestInput!) {
    approveClinicCreationRequest(data: $data) {
      message
      statusCode
    }
  }
`;

export const ADD_DOCTOR_MEMBERSHIP = gql`
  mutation MyMutation($data: AddMembershipPlanRequest!) {
    addDoctorMembershipPlan(data: $data) {
      message
      statusCode
    }
  }
`;

export const ADD_SCHEDULE = gql`
  mutation MyMutation($data: AddScheduleRequest!) {
    addSchedule(data: $data) {
      message
      statusCode
    }
  }
`;
export const REMOVE_SCHEDULE = gql`
  mutation MyMutation($data: RemoveScheduleRequest!) {
    removeSchedule(data: $data) {
      message
      statusCode
    }
  }
`;
export const REMOVE_SCHEDULE_GROUP = gql`
  mutation MyMutation($data: RemoveScheduleGroupRequest!) {
    removeScheduleGroup(data: $data) {
      message
      statusCode
    }
  }
`;
export const CLOSE_TIME_SLOT = gql`
  mutation MyMutation($data: CloseTimeslotRequest!) {
    closeTimeslot(data: $data) {
      message
      statusCode
    }
  }
`;
export const OPEN_TIME_SLOT = gql`
  mutation MyMutation($data: CloseTimeslotRequest!) {
    openTimeslot(data: $data) {
      message
      statusCode
    }
  }
`;

export const ADD_NEW_EMPLOYEE = gql`
  mutation MyMutation($data: AddEmployee!) {
    addEmployee(data: $data) {
      message
      statusCode
    }
  }
`;

export const UPLOAD_CLINIC_IMAGE = gql`
  mutation MyMutation($data: UpdateClinicRequest!) {
    updateClinic(data: $data) {
      message
      statusCode
    }
  }
`;

export const ADD_ACCOUNT_PATIENT = gql`
  mutation MyMutation($data: AddNewPatient!) {
    addNewPatient(data: $data) {
      message
      statusCode
    }
  }
`;
export const ADD_DOCTOR_FEE = gql`
  mutation MyMutation($data: AddDoctorFeeRequest!) {
    addDoctorFee(data: $data) {
      message
      statusCode
    }
  }
`;

export const ACTIVATE_DOCTOR_FEE = gql`
  mutation MyMutation($data: ActivateDoctorFeeRequest!) {
    activateDoctorFee(data: $data) {
      message
      statusCode
    }
  }
`;

export const ADD_NEW_PATIENT = gql`
  mutation MyMutation($data: AddPatientRequest = {}) {
    addPatient(data: $data) {
      message
      statusCode
    }
  }
`;
export const ADD_NOTE = gql`
  mutation MyMutation($data: addNoteInput!) {
    addNote(data: $data) {
      data {
        diagnosis {
          diagnosisName
          message
        }
        patientComplaints {
          text
          updatedAt
          updatedBy {
            firstName
            lastName
          }
        }
        procedure {
          message
          procedureName
        }
        planOfCare
        additionalAdvice
        bloodPressure
        bodyTemp
        height
        id
        immunizationStatus
        instruction
        nutritionalAdvice
        observation
        prognosis
        respRate
        pulseRate
        tempUnits
        visionTable {
          autoRefrectometer {
            leftEye {
              axis
              cyl
              spl
            }
            rightEye {
              axis
              cyl
              spl
            }
            label
          }
          eyePressure {
            iop {
              od
              os
            }
            method
            pachy {
              od
              os
            }
            time
          }
          vision {
            distance {
              withGlass {
                od
                os
              }
              unaided {
                od
                os
              }
              withPHole {
                od
                os
              }
            }
            near {
              unaided {
                od
                os
              }
              withGlass {
                od
                os
              }
              withPHole {
                od
                os
              }
            }
          }
        }
        visionTableExists
        weight
        weightUnits
      }
      message
      statusCode
    }
  }
`;

export const ADD_VACCINATION = gql`
  mutation MyMutation($data: AddVaccinationRequest!) {
    addVaccination(data: $data) {
      message
      statusCode
    }
  }
`;

export const ADD_VACCINATION_TEMPLATE = gql`
  mutation MyMutation($data: VaccinationTemplateRequest!) {
    addVaccinationTemplate(data: $data) {
      message
      statusCode
    }
  }
`;

export const UPDATE_VACCINATION = gql`
  mutation MyMutation($data: UpdateVaccinationRequest!) {
    updateVaccination(data: $data) {
      message
      statusCode
    }
  }
`;
export const ADD_APPOINTMENT_BY_DOCTOR = gql`
  mutation MyMutation($data: AddAppointmentByDoctorRequest!) {
    addAppointmentByDoctor(data: $data) {
      data {
        id
      }
      message
      statusCode
    }
  }
`;
export const CANCEL_APPOINTMENT = gql`
  mutation MyMutation($data: DeclineAppointmentRequest!) {
    cancelAppointment(data: $data) {
      message
      statusCode
    }
  }
`;
export const ADD_EMPLOYEE = gql`
  mutation MyMutation($data: AddEmployee!) {
    addEmployee(data: $data) {
      message
      statusCode
    }
  }
`;

export const ADD_PATIENT_BY_DOCTOR = gql`
  mutation MyMutation($data: AddPatientByDoctorRequest!) {
    addPatientByDoctor(data: $data) {
      data {
        id
      }
      message
      statusCode
    }
  }
`;
export const ACTIVATE_EMPLOYEE = gql`
  mutation MyMutation($data: DeactivateEmployee!) {
    activateEmployee(data: $data) {
      message
      statusCode
    }
  }
`;
export const DEACTIVATE_EMPLOYEE = gql`
  mutation MyMutation($data: DeactivateEmployee!) {
    deactivateEmployee(data: $data) {
      message
      statusCode
    }
  }
`;

export const ADD_PATIENT_BY_LINK = gql`
  mutation MyMutation($data: AddPatientByLinkRequest!) {
    addPatientByLink(data: $data) {
      message
      statusCode
    }
  }
`;

export const ADD_EYE_PRESCRIPTION_GLASSES = gql`
  mutation MyMutation($data: AddEyePrescriptionInput!) {
    addEyePrescriptionGlasses(data: $data) {
      data {
        eyePrescription {
          leftEye {
            distance {
              axis
              cyl
              spl
              va
            }
            near {
              axis
              cyl
              spl
              va
            }
            pg {
              axis
              cyl
              spl
              va
            }
          }
          rightEye {
            near {
              axis
              cyl
              spl
              va
            }
            distance {
              axis
              cyl
              spl
              va
            }
            pg {
              axis
              cyl
              spl
              va
            }
          }
        }
      }
      message
      statusCode
    }
  }
`;

export const UPDATE_PATIENT = gql`
  mutation MyMutation($data: UpdatePatientRequest!) {
    updatePatient(data: $data) {
      message
      statusCode
      data {
        allergies {
          allergyType
          name
          reaction
          severity
        }
      }
    }
  }
`;

export const READ_NOTIFICATION = gql`
  mutation MyMutation($data: GetNotification!) {
    readNotification(data: $data) {
      statusCode
    }
  }
`;
export const CREATE_RAZORPAY_SUBSCRIPTION = gql`
  mutation MyMutation($data: String!) {
    createRazorpaySubscription(clinicId: $data) {
      message
      statusCode
    }
  }
`;
export const CANCEL_RAZORPAY_SUBSCRIPTION = gql`
  mutation MyMutation($data: String!) {
    cancelRazorpaySubscription(clinicId: $data) {
      message
      statusCode
    }
  }
`;
export const UPLOAD_FILE_FOR_CLINIC = gql`
  mutation MyMutation($fileType: GenericUploadFileType!) {
    uploadCustomizedPolicyClinic(fileType: $fileType) {
      data {
        name
        url
      }
      message
      statusCode
    }
  }
`;

export const CREATE_PRESCRIPTION_PDF = gql`
  mutation MyMutation($data: CreatePrescriptionPdfRequest!) {
    createPrescriptionPdf(data: $data) {
      base64
      status
    }
  }
`;

export const CREATE_INVOICE_PDF = gql`
  mutation MyMutation($data: CreateInvoicePdfRequest!) {
    createInvoicePdf(data: $data) {
      base64
      status
    }
  }
`;
