import React from "react";
import {
  notificationInitialState,
  notificationsReducer,
} from "./notificationReducer";

const Store = React.createContext();
Store.displayName = "Store";

export const useStore = () => React.useContext(Store);

export const StoreProvider = ({ children, initialState, reducer }) => {
  //Complete Lab Data
  const [globalState, dispatch] = React.useReducer(reducer, initialState);

  const [globalStateNotifications, dispatchNotifications] = React.useReducer(
    notificationsReducer,
    notificationInitialState
  );

  return (
    <Store.Provider
      value={{
        globalState,
        dispatch,

        globalStateNotifications,
        dispatchNotifications,
      }}
    >
      {children}
    </Store.Provider>
  );
};
