import { gql } from "@apollo/client";

export const GET_FILE_FOR_DOCG = gql`
  query MyQuery {
    getFileForDocg(fileType: TermsAndConditionsForClinic) {
      data {
        url
        name
      }
      message
      statusCode
    }
  }
`;

export const GET_CLINIC = gql`
  query MyQuery($data: String!) {
    getClinicTermsAndConditions(clinicId: $data) {
      data {
        clinicTermsAndConditionsForApp {
          file
          fileName
          genericAccepted
          updatedAt
          updatedDate
          createdAt
        }
        logoLink
      }
      message
      statusCode
    }
  }
`;
