import { createBrowserHistory } from "history";
import React, { Suspense } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { Router } from "react-router-dom";
import "./App.scss";
import "./global.scss";
import Routers from "./navigators/Routers";
import { globalReducer, initialState } from "./store/GlobalReducer";
import { StoreProvider } from "./store/Store";

import { ReactQueryDevtools } from "react-query/devtools";

const App = () => {
  React.useEffect(() => {
    const appHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty("--app-height", `${window.innerHeight - 1}px`);
    };
    window.addEventListener("resize", appHeight);
    appHeight();
  }, []);
  const history = createBrowserHistory();
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnMount: true,
      },
    },
  });
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <StoreProvider initialState={initialState} reducer={globalReducer}>
          <Suspense fallback={"Loading..."}>
            <Router history={history}>
              <Routers />
              <ReactQueryDevtools
                initialIsOpen={false}
                toggleButtonProps={{ style: { opacity: 0.2 } }}
              />
            </Router>
          </Suspense>
        </StoreProvider>
      </QueryClientProvider>
    </>
  );
};

export default App;
