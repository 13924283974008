import { Checkbox, Input, setOptions } from "@mobiscroll/react5";
import { useFormik } from "formik";
import React, { Suspense } from "react";
import { Card } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import LogoImage from "src/assets/plus-logo.svg";
import Loader from "src/components/ClinicDashboard/common/Loader";
import {
  GET_CLINIC,
  GET_FILE_FOR_DOCG,
} from "src/components/graphql/GraphQLquery";
import * as Yup from "yup";
import { getQueryUnAuth } from "../../ApolloConnection/UnauthorizedConnection/ApolloProviderSignup";
import { addPatientByLink } from "./api/index";

// xzc

setOptions({
  theme: "ios",
  themeVariant: "light",
});

const AddPatientByUrl = () => {
  const [loader, setLoader] = React.useState(false);
  const { clinicId } = useParams();

  const { data: links } = useQuery(
    ["disclaimer for clinic", clinicId],
    async function ({ queryKey }) {
      try {
        let data = {
          patient_app: null,
          patient_docg: null,
          logo_link: null,
        };

        const res = await getQueryUnAuth(GET_CLINIC, clinicId);
        const _res = await getQueryUnAuth(GET_FILE_FOR_DOCG);
        data.patient_docg = _res?.data?.getFileForDocg?.data?.url;
        data.patient_app =
          res?.data?.getClinicTermsAndConditions?.data?.clinicTermsAndConditionsForApp.file;
        data.logo_link = res?.data?.getClinicTermsAndConditions?.data?.logoLink;
        return data;
      } catch (err) {
        console.log(err);
        return null;
      }
    }
  );

  const {
    values,
    touched,
    errors,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    setTouched,
    handleReset,
    setSubmitting,
    isSubmitting,
  } = useFormik({
    validateOnChange: true,
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      termsAndConditionAccepted: false,
      clinicDisclaimerAccepted: false,
    },
    initialTouched: {
      firstName: false,
      lastName: false,
      phoneNumber: false,
      email: false,
      termsAndConditionAccepted: false,
      clinicDisclaimerAccepted: false,
    },
    onSubmit: async function (values) {
      setSubmitting(true);
      if (values.phoneNumber) {
        values.phoneNumber = "+91" + values.phoneNumber;
      }

      values.clinicId = clinicId;
      const res = await addPatientByLink(values);

      if (res) {
        console.log(res);
        //   history.push(`/patient-record/${res.id}`)
        handleReset();
      }
      setSubmitting(false);
    },
    validationSchema: Yup.object().shape({
      termsAndConditionAccepted: Yup.bool().oneOf(
        [true],
        "*Field must be checked"
      ),
      clinicDisclaimerAccepted: Yup.bool().oneOf(
        [true],
        "*Field must be checked"
      ),
      firstName: Yup.string().required("*required"),
      lastName: Yup.string().required("*required"),
      phoneNumber: Yup.string()
        .matches(
          /^[6789][0-9]{9}$/,
          "Enter Valid 10 digit Mobile Number without country code"
        )
        .required("*required"),

      email: Yup.string()
        .email("Enter valid Email")
        .matches(
          /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
          "Enter valid email"
        )
        .required("*required"),
    }),
  });

  console.log(links);

  return (
    <>
      <Loader show={loader || isSubmitting} />
      <Helmet>
        <title>Patient Registration | DOCG</title>
      </Helmet>
      <div className="min-vh-100 d-flex flex-column">
        <Suspense fallback={<div>Loading...</div>}>
          <Card className="docg-card m-4 w-auto h-100">
            <Card.Header
              className="d-flex justify-content-between customCardHeader"
              style={{ paddingTop: "0", paddingBottom: "0" }}
            >
              <Card.Title className="docg-card-title customCardTitle">
                Register Yourself
              </Card.Title>
              <img
                src={links?.logo_link}
                style={{ width: "50px" }}
                alt=""
                onError={(e) => alert(e)}
              />
            </Card.Header>
            <Card.Body className="docg-card-body pb-4 pt-4">
              <form onSubmit={handleSubmit} className="mbsc-row">
                <div className="mbsc-col-12">
                  <Input
                    inputStyle="underline"
                    labelStyle="floating"
                    label="First Name*"
                    placeholder=""
                    name="firstName"
                    value={values?.firstName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors?.firstName && touched?.firstName}
                    errorMessage={errors?.firstName}
                  />
                </div>
                <div className="mbsc-col-12">
                  <Input
                    inputStyle="underline"
                    labelStyle="floating"
                    label="Last Name*"
                    placeholder=""
                    name="lastName"
                    value={values?.lastName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors?.lastName && touched?.lastName}
                    errorMessage={errors?.lastName}
                  />
                </div>
                <div className="mbsc-col-12">
                  <Input
                    inputStyle="underline"
                    labelStyle="floating"
                    label="Phone Number*"
                    placeholder=""
                    name="phoneNumber"
                    type="number"
                    value={values?.phoneNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors?.phoneNumber && touched?.phoneNumber}
                    errorMessage={errors?.phoneNumber}
                  />
                </div>
                <div className="mbsc-col-12">
                  <Input
                    inputStyle="underline"
                    labelStyle="floating"
                    label="Email*"
                    placeholder=""
                    name="email"
                    value={values?.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors?.email && touched?.email}
                    errorMessage={errors?.email}
                  />
                </div>
                <div className="mbsc-col-12 mt-4 d-flex align-items-center custom-repeat-checkbox-cont">
                  <Checkbox
                    value="termsAndConditionAccepted"
                    checked={values.termsAndConditionAccepted}
                    onChange={(e) => {
                      console.log(e.target.checked);
                      setFieldValue(
                        "termsAndConditionAccepted",
                        e.target.checked,
                        true
                      );
                      setTouched({ termsAndConditionAccepted: true }, false);
                    }}
                  ></Checkbox>
                  <a
                    className="ml-n4"
                    href={links?.patient_docg}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms and Conditions from DocG
                  </a>
                  <div className="mbsc-ios mbsc-error-message">
                    {errors.termsAndConditionAccepted}
                  </div>
                </div>
                <div className="mbsc-col-12 mt-4 d-flex align-items-center custom-repeat-checkbox-cont">
                  <Checkbox
                    value="termsAndConditionAccepted"
                    checked={values.clinicDisclaimerAccepted}
                    onChange={(e) => {
                      setFieldValue(
                        "clinicDisclaimerAccepted",
                        e.target.checked,
                        true
                      );
                      setTouched({ clinicDisclaimerAccepted: true }, false);
                    }}
                  ></Checkbox>
                  <a
                    className="ml-n4"
                    href={links?.patient_app}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms and Conditions from Clinic for Patient
                  </a>
                  <div className="mbsc-ios mbsc-error-message">
                    {errors.clinicDisclaimerAccepted}
                  </div>
                </div>
                <div className="mbsc-col-12 mt-2">
                  <button className="docg-save-btn" type="submit">
                    Submit
                  </button>
                </div>
              </form>
            </Card.Body>
          </Card>

          <div
            className="sidebar-footer d-flex align-items-end justify-content-center flex-grow-1"
            style={{ marginBottom: "0" }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexGrow: 1,
              }}
            >
              <span className="text-purple">Powered by: </span>
              <img style={{ width: "4rem" }} src={LogoImage} alt="" />{" "}
            </div>
          </div>
        </Suspense>
      </div>
    </>
  );
};

export default AddPatientByUrl;
