import { snackbar } from "@mobiscroll/react5";
import { postMutationUnAuth } from "src/components/ApolloConnection/UnauthorizedConnection/ApolloProviderSignup";
import { ADD_PATIENT_BY_LINK } from "src/components/graphql/GraphQLmutation";

export async function addPatientByLink(data) {
  console.log(data);
  try {
    let response = await postMutationUnAuth(ADD_PATIENT_BY_LINK, data);
    if (
      response.data !== null &&
      response?.data?.addPatientByLink?.statusCode >= 200 &&
      response?.data?.addPatientByLink?.statusCode < 300
    ) {
      snackbar({
        message: "Patient Added Successfully.",
        color: "success",
      });
      return true;
    } else {
      throw new Error(response?.data?.addPatientByLink.message);
    }
  } catch (e) {
    console.log(e.message);
    snackbar({
      message: e.message,
      color: "danger",
    });
    return null;
  }
}
